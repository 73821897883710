import { Connection, SystemProgram, Transaction, clusterApiUrl } from '@solana/web3.js';

// import { PhantomProvider } from './types';
import getProvider from './getProvider.ts';
// import createTransferTransaction from './createTransferTransaction.ts';


/**
 * Signs and sends transaction
 * @param   {PhantomProvider} provider    a Phantom Provider
 * @param   {Transaction}     transaction a transaction to sign
 * @returns {Transaction}                 a signed transaction
 */
const preflightCommitment = "processed";

const connectWeb3 = async () => {
    console.log('connectWeb3-----');


    const provider = getProvider(); // see "Detecting the Provider"

    try {
        const resp = await provider.connect();
        console.log(resp.publicKey.toString());
        global.publicKey = resp.publicKey.toString();
        localStorage.setItem('publicKey', global.publicKey)
      
    } catch (err) {
        console.log('User rejected the request',err);
        
        // { code: 4001, message: 'User rejected the request.' }
    }
    provider.on("connect", () => console.log("connected!"));
    console.log(provider.publicKey.toString());
    // 26qv4GCcx98RihuK3c4T6ozB3J7L6VwCuFVc7Ta2A3Uo 
    console.log(provider.isConnected);
    global.provider = provider;
   
    // true
    provider.on('accountChanged', (publicKey) => {
        if (publicKey) {
            // Set new public key and continue as usual
            console.log(`Switched to account ${publicKey.toBase58()}`);
        }
    });

};

export default connectWeb3;
