<template>

    <!-- <div class="mui-1ts5bj3">
        <div class="mui-1yc2rrq">
            <div v-if="showMedia" class="MuiContainer-root MuiContainer-maxWidthLg MuiContainer-fixed mui-5sjj46">
                <div class="mui-14647vy">
                    <div role="button" class="mui-1tcm166"><img alt="Logo" fetchpriority="high" width="167" height="64"
                            decoding="async" data-nimg="1" src="/images/logo2.png" style="color: transparent;"></div>
                    <ul class="mui-1ftj51d">

                        <li class="mui-70qvj9">
                            <div class="MuiBox-root mui-1hqbf0m">
                                <a class="mui-19007wn" href="/#about-us" style="width: 9px;">
                                    <svg class="svg-class" version="1.1" height="24"
                                        filter="drop-shadow(0px 2px 0px #9F7756)" paint-order="stroke"
                                        stroke-linecap="butt" stroke-linejoin="miter" width="100"
                                        viewBox="-1 0 90.0250244140625 24">

                                        <text class="text-class" text-anchor="middle" x="50%" y="70%" stroke="#9F7756"
                                            stroke-width="2" fill="#F5F5F5" font-family="Gameria" font-size="18"
                                            letter-spacing="1px" font-weight="400">About Us</text>
                                    </svg></a>
                            </div>
                        </li>
                        <li class="mui-70qvj9">
                            <div class="MuiBox-root mui-1hqbf0m"><a class="mui-19007wn" href="/#workflow"
                                    style="width: 99px;"><svg class="svg-class" version="1.1" height="24"
                                        filter="drop-shadow(0px 2px 0px #9F7756)" paint-order="stroke"
                                        stroke-linecap="butt" stroke-linejoin="miter" width="110"
                                        viewBox="-1 0 98.8125 24">

                                        <text class="text-class" text-anchor="middle" x="50%" y="70%" stroke="#9F7756"
                                            stroke-width="2" fill="#F5F5F5" font-family="Gameria" font-size="18"
                                            letter-spacing="1px" font-weight="400">Gameflow</text>
                                    </svg></a></div>
                        </li>
                        <li class="mui-70qvj9">
                            <div class="MuiBox-root mui-1hqbf0m"><a class="mui-19007wn" href="/#roadmap"
                                    style="width: 88px;"><svg class="svg-class" version="1.1" height="24"
                                        filter="drop-shadow(0px 2px 0px #9F7756)" paint-order="stroke"
                                        stroke-linecap="butt" stroke-linejoin="miter" width="100"
                                        viewBox="-1 0 87.9749984741211 24">

                                        <text class="text-class" text-anchor="middle" x="50%" y="70%" stroke="#9F7756"
                                            stroke-width="2" fill="#F5F5F5" font-family="Gameria" font-size="18"
                                            letter-spacing="1px" font-weight="400">Roadmap</text>
                                    </svg></a></div>
                        </li>
                        <li class="mui-70qvj9">
                            <div class="MuiBox-root mui-1hqbf0m"><a class="mui-19007wn"
                                    href="https://whitepaper.memefi.club/" style="width: 113px;"><svg class="svg-class"
                                        version="1.1" height="24" filter="drop-shadow(0px 2px 0px #9F7756)"
                                        paint-order="stroke" stroke-linecap="butt" stroke-linejoin="miter" width="125"
                                        viewBox="-1 0 112.98750305175781 24">

                                        <text class="text-class" text-anchor="middle" x="50%" y="70%" stroke="#9F7756"
                                            stroke-width="2" fill="#F5F5F5" font-family="Gameria" font-size="18"
                                            letter-spacing="1px" font-weight="400">Whitepaper</text>
                                    </svg></a></div>
                        </li>
                        <li class="mui-70qvj9">
                            <div class="MuiBox-root mui-1hqbf0m"><a class="mui-19007wn"
                                    href="https://whitepaper.memefi.club/" style="width: 113px;"><svg class="svg-class"
                                        version="1.1" height="24" filter="drop-shadow(0px 2px 0px #9F7756)"
                                        paint-order="stroke" stroke-linecap="butt" stroke-linejoin="miter" width="187"
                                        viewBox="-1 0 112.98750305175781 24">

                                        <text class="text-class" text-anchor="middle" x="50%" y="70%" stroke="#9F7756"
                                            stroke-width="2" fill="#F5F5F5" font-family="Gameria" font-size="18"
                                            letter-spacing="1px" font-weight="400">Wheel of
                                            Fortune</text>
                                    </svg></a></div>
                        </li>
                         <li class="mui-70qvj9">
                            <div class="MuiBox-root mui-1hqbf0m"><button href="" class="mui-19007wn"
                                    style="width: 205px;"><svg class="svg-class" version="1.1" height="24"
                                        filter="drop-shadow(0px 2px 0px #9F7756)" paint-order="stroke"
                                        stroke-linecap="butt" stroke-linejoin="miter"
                                        width="173" viewBox="-1 0 173 24">
                                       
                                        <text class="text-class" text-anchor="middle" x="50%" y="70%" stroke="#9F7756"
                                            stroke-width="2" fill="#F5F5F5" font-family="Gameria" font-size="18"
                                            letter-spacing="1px" font-weight="400">Wheel of
                                            Fortune</text>
                                    </svg></button></div>
                        </li>
                    </ul>
                </div>
                <div class="mui-x4jt02"><button
                        class="MuiButtonBase-root MuiButton-root MuiButton-newSecondary MuiButton-newSecondaryPrimary MuiButton-sizeMedium MuiButton-newSecondarySizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-newSecondary MuiButton-newSecondaryPrimary MuiButton-sizeMedium MuiButton-newSecondarySizeMedium MuiButton-colorPrimary mui-1wrj2xr"
                        tabindex="0" type="button"><span>Sign in</span></button>
                    <div class="MuiBox-root mui-68zbsl"><button
                            class="MuiButtonBase-root MuiButton-root MuiButton-newPrimary MuiButton-newPrimaryPrimary MuiButton-sizeMedium MuiButton-newPrimarySizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-newPrimary MuiButton-newPrimaryPrimary MuiButton-sizeMedium MuiButton-newPrimarySizeMedium MuiButton-colorPrimary mui-1sc8h5u"
                            tabindex="0" type="button" data-cy="lead"><span>Start playing</span></button></div>
                </div>
            </div>
           
        </div>
    </div> -->


    <header class="header-top">
        <button class="menu-trigger" id="menu01">
            <span></span>
            <span></span>
            <span></span>
        </button>
        <h1 class="rs">
            <a href="#" class="logo animated fadeInDown"></a>
        </h1>
        <div class="list-menu" id="mainmenu">
            <ul class="rs menu-left">
                <li><a href="https://whitepager.duckycoin.club/home" target="_blank">Whitepager</a></li>
                <li><a href="#section3-1">About</a></li>
                <li><a href="#section3">Hero</a></li>
                <li><a href="#game">Gameflow</a></li>
                <li><a href="#section7">ParTners</a></li>
                <!-- <li><router-link to="/">Whitepaper</router-link></li> -->
                <!-- <li><a href="/">HOME</a></li> -->

                <li>
                    <ul style="display: block;">
                        <li>

                            <a href="#" v-if="publicKey != ''" v-on:click="signAndSendTransaction()"
                                class="btn-connect">Play now</a>

                            <a href="#" v-else v-on:click="connectWeb3()" class="btn-connect">Play now</a>

                        </li>
                        <li v-if="publicKey != ''">
                            <div style="display: block;color:white;text-align: center;">ID: {{ publicKey }}</div>
                        </li>
                    </ul>

                </li>
                <li>
                    <ul style="display: block;">
                        <li>
                            <router-link to="/auth" class="btn-signin">Sign In</router-link>
                        </li>

                    </ul>

                </li>
            </ul>



        </div>

    </header>



</template>

<script>

// export default {
//     data() {
//         return {
//             showMedia: false,
//         };
//     },
//     created() {
//         const width = window.innerWidth;
//         if (width >= 1199) {
//             this.showMedia = true;
//         }
//     },

// }

function truncate(str, n) {
    if (str) {
        return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
    } else {
        return "";
    }
}
import signAndSendTransaction from '../../components/Web3/signAndSendTransaction.ts';
import connectWeb3 from '../../components/Web3/connectWeb3.ts';

console.log('signAndSendTransaction', signAndSendTransaction);
var publicKey = truncate(localStorage.getItem('publicKey'), 15);
console.log('publicKey', publicKey);

export default {
    data() {
        return {
            publicKey
        };
    },
    methods: {
        signAndSendTransaction: signAndSendTransaction,
        connectWeb3: connectWeb3,

    },
};

</script>


<style scoped>
.text-class,
.svg-class {
    font-family: 'Agency FB Bold';

    transition: all .3s;
}

.text-class:hover {
    fill: #F9D286;
    stroke: #925508;
}

.svg-class:hover {
    filter: drop-shadow(0px 2px 0px #915608);
}

.mui-1yc2rrq {
    display: flex;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
    padding: 20px 0px;
    gap: 24px 0px;
    background: linear-gradient(rgba(52, 36, 25, 0.8) 58.13%, rgba(52, 36, 25, 0) 100%);
    backdrop-filter: blur(1px);
}

.mui-1ts5bj3 {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 50;
}

@media (min-width: 600px) {
    .mui-5sjj46 {
        max-width: 1270px;
        width: 100%;
    }
}

@media (min-width: 600px) {
    .mui-5sjj46 {
        max-width: 375px;
        width: 100%;
    }
}

@media (min-width: 1200px) {
    .mui-5sjj46 {
        max-width: 1200px;
    }
}

@media (min-width: 1536px) {
    .mui-5sjj46 {
        max-width: 1536px;
    }
}

@media (min-width: 1200px) {
    .mui-5sjj46 {
        max-width: 1200px;
    }
}

@media (min-width: 1024px) {
    .mui-5sjj46 {
        max-width: 1024px;
    }
}

@media (min-width: 900px) {
    .mui-5sjj46 {
        max-width: 900px;
    }
}

@media (min-width: 600px) {
    .mui-5sjj46 {
        max-width: 600px;
    }
}

@media (min-width: 375px) {
    .mui-5sjj46 {
        max-width: 375px;
    }
}

@media (min-width: 600px) {
    .mui-5sjj46 {
        padding-left: 12px;
        padding-right: 12px;
    }
}

.mui-14647vy {
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    gap: 32px;
}

.mui-x4jt02 {
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    gap: 8px;
}

.mui-1tcm166 {
    width: fit-content;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    cursor: pointer;
}

.mui-1ftj51d {
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    list-style: none;
    padding: 0px;
    margin: 0px;
    gap: 18px;
}

.mui-70qvj9 {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.mui-1wrj2xr {
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    color: inherit;
    text-transform: uppercase;
    min-width: 64px;
    box-sizing: border-box;
    -webkit-box-pack: center;
    justify-content: center;
    white-space: nowrap;
    transition: all 0.25s ease-in 0s;
    height: 48px;
    padding: 12px 20px;
    border-radius: 16px;
    font-family: 'Agency FB Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 1px;
    -webkit-text-fill-color: unset;
    -webkit-text-stroke: 1px rgb(139, 129, 116);
    background: rgb(69, 50, 33);
    border: 2px solid rgb(76, 55, 36);
    box-shadow: rgb(76, 55, 37) 0px 4px 0px 0px;
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: row;
}

.mui-1wrj2xr>span {
    color: rgb(244, 240, 235);
    -webkit-text-stroke-width: 0px;
    text-shadow: rgb(159, 119, 86) -1px -1px 0px, rgb(159, 119, 86) 0px -1px 0px, rgb(159, 119, 86) 1px -1px 0px, rgb(159, 119, 86) 1px 0px 0px, rgb(159, 119, 86) 1px 1px 0px, rgb(159, 119, 86) 0px 1px 0px, rgb(159, 119, 86) -1px 1px 0px, rgb(159, 119, 86) -1px 0px 0px;
    filter: drop-shadow(rgb(159, 119, 86) 0px 2px 0px);
}

.mui-1wrj2xr>span {
    font-family: 'Agency FB Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 1px;
    background: linear-gradient(rgb(246, 245, 244) 0%, rgb(208, 204, 200) 115.91%) text;
    -webkit-text-fill-color: unset;
    -webkit-text-stroke: 1px rgb(139, 129, 116);
}

.mui-1wrj2xr>span {
    font-family: 'Agency FB Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 36px;
    letter-spacing: 1px;
    background: linear-gradient(rgb(246, 245, 244) 0%, rgb(208, 204, 200) 115.91%) text;
    -webkit-text-fill-color: unset;
    -webkit-text-stroke: 1px rgb(139, 129, 116);
    white-space: nowrap;
}

.mui-68zbsl {
    width: auto;
}

.mui-1sc8h5u {
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    color: inherit;
    text-transform: uppercase;
    min-width: 64px;
    box-sizing: border-box;
    -webkit-box-pack: center;
    justify-content: center;
    white-space: nowrap;
    transition: all 0.25s ease-in 0s;
    height: 48px;
    padding: 12px 20px;
    border-radius: 16px;
    font-family: 'Agency FB Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 1px;
    -webkit-text-fill-color: unset;
    -webkit-text-stroke: 1px rgb(139, 129, 116);
    background: rgb(226, 154, 54);
    border: 2px solid rgb(163, 112, 41);
    box-shadow: rgb(163, 112, 41) 0px 4px 0px;
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: row;
    gap: 8px;
}


.mui-5sjj46 {
    margin-left: auto;
    box-sizing: border-box;
    margin-right: auto;
    padding-left: 8px;
    padding-right: 8px;
    max-width: 1270px;
    width: 100%;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

@media (max-width: 1199.95px) {
    .mui-5sjj46 {
        padding: 0px 24px;
    }
}

@media (min-width: 600px) {
    .mui-5sjj46 {
        max-width: 1270px;
        width: 100%;
    }
}

@media (max-width: 1199.95px) {
    .mui-5sjj46 {
        max-width: 1270px;
        width: 100%;
    }
}

@media (min-width: 600px) {
    .mui-5sjj46 {
        max-width: 375px;
        width: 100%;
    }
}

@media (min-width: 600px) {
    .mui-5sjj46 {
        max-width: 600px;
    }
}

@media (min-width: 375px) {
    .mui-5sjj46 {
        max-width: 375px;
    }
}

@media (min-width: 600px) {
    .mui-5sjj46 {
        padding-left: 12px;
        padding-right: 12px;
    }
}

.mui-5sjj46 {
    margin-left: auto;
    box-sizing: border-box;
    margin-right: auto;
    padding-left: 8px;
    padding-right: 8px;
    max-width: 1270px;
    width: 100%;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
}
</style>