import axios from 'axios';

class UserService {
  constructor() {
    this.axios = axios.create({
      baseURL: 'https://tdserver.cryptopho.io/', // Replace with your actual API endpoint
    });
  }

  async getUsers() {
    const response = await this.axios.get('users');
    return response.data;
  }

  async getUser(id) {
    const response = await this.axios.get(`users/${id}`);
    return response.data;
  }

  async postLogin(credentials) {
    try {
      const response = await this.axios.post('login', credentials, );

      // Handle successful login
      console.log('Login successful!', response.data);
      return response.data; // Optionally return login response data
    } catch (error) {
      // Handle login errors gracefully
      console.error('Login error:', error);
      throw error; // Re-throw the error for handling in the component
    }
  }
}

export default new UserService();
