<template>

  <div class="container mx-auto px-4 h-full">
    <div v-if="alertOpen" class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-orange-500">
      <span class="text-xl inline-block mr-5 align-middle">
        <i class="fas fa-bell"></i>
      </span>
      <span class="inline-block align-middle mr-8">
        {{ error }}
      </span>
    </div>
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
          <div class="rounded-t mb-0 px-6 py-6">
            <!-- <div class="text-center mb-3">
              <h6 class="text-blueGray-500 text-sm font-bold">
                Sign in with
              </h6>
            </div> -->

            <hr class="mt-6 border-b-1 border-blueGray-300" />
          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">

            <form>
              <div class="relative w-full mb-3">
                <label for="grid-password"
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Username</label>
                <input v-model="username" type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Username" />
              </div>

              <div class="relative w-full mb-3">
                <label for="grid-password"
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Password</label>
                <input v-model="password" type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Password" />
              </div>

              <div>
                <label class="inline-flex items-center cursor-pointer">
                  <input v-model="rememberMe" id="customCheckLogin" type="checkbox"
                    class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150" />
                  <span class="ml-2 text-sm font-semibold text-blueGray-600">Remember me</span>
                </label>
              </div>


              <div class="text-center mt-6">
                <button @click.prevent="handleSubmit"
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="button">
                  Sign In
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 relative">
          <div class="w-1/2">
            <a href="javascript:void(0)" class="text-blueGray-200">
              <small>Forgot password?</small>
            </a>
          </div>
          <div class="w-1/2 text-right">
            <router-link to="/auth/register" class="text-blueGray-200">
              <small>Create new account</small>
            </router-link>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>



<script>

import UserService from '@/services/userService';

export default {
  name: 'App',
  data() {
    return {
      username: '',
      password: '',
      error: null,
      rememberMe: false,
      alertOpen: false
    };
  },
  methods: {
    closeAlert: function () {
      this.alertOpen = false;

    },
    openAlert: function () {
      this.alertOpen = true;
      setTimeout(() => {
        this.alertOpen = false;
        this.error = null;
      }, 3000);
    },
    async handleSubmit() {
      console.log("submit");
      if (this.username.length < 6 || this.username.length > 10) {
        this.error = "User name has 6-10 characters including numbers and letters ";
        this.openAlert();
        return;
      }
      if (this.password.length < 6 || this.password.length > 10) {
        this.error = "Password has 6-10 characters including numbers and letters ";
        this.openAlert();
        return;
      }
      const credentials = {
        username: this.username,
        password: this.password,
      };

      await UserService.postLogin(credentials)
        .then(() => {
          console.log("ok");
          // this.$router.push('/');
          window.location.replace("/");

        })
        .catch((error) => {
          console.log("error : " + error);
          this.error = "account or password is incorrect";
          this.openAlert();
        });

      // try {
      //   await UserService.postLogin(credentials);
      //   this.$router.push('/');
      // } catch (error) {

      //   this.alertOpen = true;
      //   this.error = error.r;
      //}
    },
  },
};



// import axios from 'axios';
// import { getUsers } from '@/services/UserService';

// export default {
//   data() {
//     return {
//       username: '',
//       password: '',
//       rememberMe: false,
//     };
//   },
//   methods: {
//     handleSubmit() {
//       // Access form data here
//       console.log('Username:', this.username);
//       console.log('Password:', this.password);
//       console.log('Remember Me:', this.rememberMe);

//       // You can process data here (e.g., validation, sending to server)

//       // Prevent default form submission if using a button type="submit"
//       event.preventDefault();
//     },
//     created() {
//       this.getUsers();
//     },
//     methods: {
//       async getUsers() {
//         const users = await getUsers();
//         this.users = users;
//       },
//     },
//   },
// };
</script>
