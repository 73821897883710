 <template> 
    <div class="thumb-pet">
        <img class="img-fluid" :src="bg" />
        <div>
            <div class="content-pet">
                <div class="name-pet">
                    <img :src="def" />
                    <span class="name-content">{{ pet.name }}</span>
                </div>
                <div class="img-pet" ref="btnDropdownRef" v-on:click="toggleDropdown($event)" v-bind:class="{
                    hidden: dropdownPopoverShow,
                    block: !dropdownPopoverShow,
                }">
                    <img class="img-fluid" :src="pet.imgPet" />
                </div>
                <div class="img-pet" ref="btnDropdownRef" v-on:click="toggleDropdown($event)" v-bind:class="{
                    hidden: !dropdownPopoverShow,
                    block: dropdownPopoverShow,
                }">
                    <img class="img-fluid img-drop" :src="pet.imgPet" />
                    <p>{{ pet.describe }}</p>

                </div>
            </div>
            <div class="product-info" style=" position: relative;">
                <div class="product-quantity">
                    <h3 class="product-title" style="color: aliceblue;"> Amount </h3>
                    <div style="margin-left: 15%;">
                    </div>

                    <button class="product-quantity-subtract" @click="decrement(1)">-</button>
                    <span style="color: aliceblue;margin-left: 5%; margin-right: 5%;"> {{ quantity }} </span>
                    <button class="product-quantity-add" @click="increment(1)">+</button>
                </div>
                <h5 class="product-price">{{ quantity * pet.sol }} Sol</h5>
                <button class="product-add-to-cart" v-on:click="btnBuy"> buy </button>
            </div>
        </div>
    </div>

</template>
<script>
import { createPopper } from "@popperjs/core";

export default {

    props: {

        // Add a prop to receive initial quantity for each product
        pet: {
            type: Object, // Define the type as an object
            required: true
        },
    },
    data() {
        return {
            dropdownPopoverShow: false,
            bg: "images/icon-1.png",
            def: "images/icon-def.png",
            quantity: this.pet.quantity
        };
    },
    methods: {
        btnBuy() {
            console.log("buy  " , this.quantity * this.pet.sol);
        },
        decrement() {
            if (this.quantity > 1) {
                this.quantity--;
            }
        },
        increment() {
            if (this.quantity < 9) {
                this.quantity++;
            }
        },
        toggleDropdown: function (event) {
            event.preventDefault();
            if (this.dropdownPopoverShow) {
                this.dropdownPopoverShow = false;
            } else {
                this.dropdownPopoverShow = true;
                createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
                    placement: "bottom-start", 
                });
            }
        },
    },
};
</script>
<style scoped>
.content-pet {
    cursor: pointer;
    max-width: 95%;
    overflow: hidden;
    word-break: break-word;
}

p {
    position: absolute;
    top: 15%;
    left: 10%;
    color: #3095ee;
}

@media (max-width: 768px) {
    .content-pet {
        font-size: 1vw;
    }
    p{
        font-size: 4vw;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .content-pet {
        font-size: 1.2vw;
    }
    p{
        font-size: 2vw;
    }
}

@media (min-width: 1025px) {
    .content-pet p{
        font-size: 1.5vw;
    }
}

.img-drop {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>