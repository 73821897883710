<template>
     <div class="unipet-wrapper">
        <IndexNavbar />
        <router-view />
        <FooterComponent></FooterComponent>
    </div>
</template>
<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";

export default {
    name: "admin-layout",
     components: {
        IndexNavbar,
        FooterComponent,   
    },
};
</script>