<template>
    <section class="section3" id="section3">
        <span class="title-2" style="margin-top: 10%;"></span>
        <div class="box-nhanvat">
            <div class="box-3">
                <PetCard v-for="pet in pets" :key="pet.name" :pet="pet" />
            </div>
        </div>
    </section>
</template>

<script>
import PetCard from './petCard.vue';

export default {
    components: {
        PetCard,
    },
    data() {
        return {
            isShowModal: false,
            pets: [
                {
                    name: 'Hero 1',
                    imgPet: 'images/heros/hero-1.png',
                    quantity: 1,
                    sol: 4,
                    id: 1,
                    describe: `
                                Its have small spikes all over it tail and
                                it will get bigger during growth. When it
                                reached adulthood, its tail, foot nad hand
                                cant emit powerful flame.
                            `

                },
                {
                    name: 'Hero 2',
                    imgPet: 'images/heros/hero-2.png',
                    quantity: 1,
                    sol: 44,
                    id: 2,
                    describe: `
                                It have powerful control over ice, can cast
                                blizzard on an area to attack enemies, with
                                the area of effect so big, no foe can
                                escape.
                             `

                },
                {
                    name: 'Hero 3',
                    imgPet: 'images/heros/hero-3.png',
                    quantity: 1,
                    sol: 6,
                    id: 3,
                    describe: `
                                Have a very ferocious exterior but actually
                                soft inside, the scale outside is very
                                durable protecting it from other
                                beasts.Usually live with pack and shoot
                                water from its mouth to have fun.
                            `
                }, {
                    name: 'Hero 4',
                    imgPet: 'images/heros/hero-4.png',
                    quantity: 1,
                    sol: 9,
                    id: 4,
                    describe: `
                                Have a very ferocious exterior but actually
                                soft inside, the scale outside is very
                                durable protecting it from other
                                beasts.Usually live with pack and shoot
                                water from its mouth to have fun.
                            `
                },
               
                // ... add more pet objects here
            ],
        };
    },
    methods: {
    }
};
</script>



<style>

@media (max-width: 768px) {
    .box-3 {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .box-3 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1025px) {
    .box-3 {
    grid-template-columns: repeat(3, 1fr);
  }
  
}
@media (min-width: 1866px) {
    .box-3 {
    grid-template-columns: repeat(4, 1fr);
  }
}

.box-3 {
    display: grid;
    grid-gap: 10px;
    grid-auto-rows: minmax(auto, 1fr);
}

.product-card {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

button {
    color: aliceblue;
}

.product-info {
    padding: 10px;
}

.product-title {
    font-size: 18px;
    font-weight: bold;
}

.product-price {
    font-size: 16px;
    color: rgb(180, 213, 33);
    text-align: center;
}

.product-quantity {
    display: flex;
    align-items: center;
}

.product-quantity-subtract,
.product-quantity-add {
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.product-quantity-input {
    width: 50px;
    text-align: center;
}

.product-add-to-cart {
    width: 100%;
    background-color: #21cce6;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
}

.product-add-to-cart:hover {
    background-color: #21cce6;
}
</style>