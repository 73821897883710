import { Connection, SystemProgram, Transaction, clusterApiUrl } from '@solana/web3.js';

// import { PhantomProvider } from './types';
import getProvider from './getProvider.ts';
// import createTransferTransaction from './createTransferTransaction.ts';


/**
 * Signs and sends transaction
 * @param   {PhantomProvider} provider    a Phantom Provider
 * @param   {Transaction}     transaction a transaction to sign
 * @returns {Transaction}                 a signed transaction
 */
const preflightCommitment = "processed";
const signAndSendTransaction = async () => {
  console.log('signAndSendTransaction-----');

  const provider = getProvider(); // see "Detecting the Provider"
  console.log('provider', provider);

  const connection = new Connection(
    clusterApiUrl("devnet"),
    preflightCommitment
  );
  let publicKey = "";
  try {
     provider.connect().then(async (resp) =>{
      let publicKey = resp.publicKey;
      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: "jgfQX8hKwe4hN2ULCfYHRzW9gsVQUw2AhZbTyy6SBau",
          lamports: 100,
        })
      );
      transaction.feePayer = publicKey;
      transaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;
      console.log('transaction.recentBlockhash', transaction.recentBlockhash);
       //start send 
    
      // try {
        const  signature  =  (await provider.signAndSendTransaction(transaction));
        console.log('done signature',signature.signature);
     
        // await  (connection.getSignatureStatus(signature));   
        const value = await connection.getSignatureStatus(signature.signature);
        console.log('value',value);
        
        const confirmationStatus = value?.confirmationStatus;
        console.log('done confirmationStatus',confirmationStatus);
    });
    // publicKey = resp.publicKey;
  } catch (err) {
    // { code: 4001, message: 'User rejected the request.' }
  }
  // console.log('provider.publicKey:', publicKey.toString());


  
  // } catch (error) {
  //   console.warn(error);
  //   throw new Error(error.message);
  // }
  //end start send
  // return transaction;


};

export default signAndSendTransaction;
